/* html,
body {
   height: 100%;
} */

.ant-table
  .ant-table-container
  .ant-table-content
  table
  thead.ant-table-thead
  .ant-table-cell {
  background-color: #c1c2c69f !important;
}

.customColumnClassName{
  flex: 1 1 auto;
  display: flex;
  margin: 5px;
  overflow-y: auto;
}

.customRowClassName {
   border-radius: 10px;
   border-color: rgb(11, 11, 11);
   border-width: 3px;
   border-style: solid;
   
 }
 
 .customRowClassName td:first-child {
   border-top-left-radius: 10px;
   border-bottom-left-radius: 10px;
 }
 
 .customRowClassName td:last-child {
   border-top-right-radius: 10px;
   border-bottom-right-radius: 10px;
 }

.highlight-bottom-border > td {
   border-bottom: solid 1px black !important
 }
 
 .highlight-top-border > td {
   border-top: solid 2px black !important;
   border-bottom: solid 2px #6d9eff !important
 }